import type { PlainObject } from '@roxen/shared'

export function initPaywall(): void {
  if ($('.paywall-page').length) {
    const pwc: PlainObject = window.NWS.getConfig('paywall')

    const pw = $(
      `<div id="paywall-overlay" class="paywall-overlay">
        <div id="paywall-container" class="paywall-container">Wait...</div>
      </div>`
    )

    $(document.body).css({ overflow: 'hidden' }).append(pw)

    $.get(pwc.formEndpoint)
      .then((res) => {
        pw.find('.paywall-container').empty().append(res)
      })
      .catch((e) => console.error('Login form error:', e))
  }
}
