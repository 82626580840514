import type { ScrollObserverFetchCallback } from '@roxen/nws-public'
import { ScrollObserver } from '@roxen/nws-public'
import type { PlainObject } from '@roxen/shared'
import { renderAds } from './ads/adnuntius'
import { layoutAds } from './ads/inline-ads-layout'

const loadOnScroll = true
let noAds = false
let isCompact = false

interface SectionData {
  ok: boolean
  error?: {
    code: number
    message: string
  }
  data?: {
    items: string[]
    nextTimestamp: number
  }
}

function showLoader(): void {
  $('.main-content__left').addClass('show-loader')
}

function hideLoader(): void {
  $('.main-content__left').removeClass('show-loader')
}

function showMoreButton(sel: string, timestamp: number): void {
  $(sel).append(
    `<a href="?fromTimestamp=${timestamp}" class="loader-button btn btn-primary">Vis flere artikler</div>`
  )
}

const fetcher: ScrollObserverFetchCallback = async (observer) => {
  showLoader()

  const vars: PlainObject = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    fromTimestamp: window.NWS.misc.nextPublishDate?.toString(),
  }

  if (window.NWS.misc.listing) {
    vars.name = window.NWS.misc.listing
  }

  const qs = Object.keys(vars)
    .map((k) => `${k}=${encodeURIComponent(vars[k])}`)
    .join('&')

  const apiEndpoint = window.NWS.misc.listing
    ? `/api/site/v1/article-list/handler/`
    : `/api/site/v1/section${window.location.pathname}`

  const x = await fetch(`${apiEndpoint}?${qs}`)

  hideLoader()

  if (x) {
    const data: SectionData = await x.json()

    if (data.ok && data.data) {
      // eslint-disable-next-line require-atomic-updates
      window.NWS.misc.nextPublishDate = data.data.nextTimestamp
      const items = data.data.items

      if (items.length) {
        const frag = document.createDocumentFragment()

        items.forEach((item) => {
          const wrap = document.createElement('div')
          wrap.innerHTML = item
          frag.appendChild(wrap)
        })

        const htmlElems = frag.querySelectorAll('.section-grid')
        observer.addItems(htmlElems)

        if (!noAds) {
          layoutAds(htmlElems, undefined, isCompact ? 2 : undefined)
          renderAds({ area: ['main-left', 'floaters'] })
        }

        return true
      } else {
        observer.unobserve()
      }
    } else {
      observer.unobserve()
    }
  }

  return false
}

export function initSectionPage(): void {
  // FIXME: Check for window.NWS.misc.nextPublishDate.
  //        This is so the code will run while developing
  if (window.NWS.misc?.isSectionPage && window.NWS.misc?.noAutoLoad !== true) {
    let list = document.querySelectorAll('.main-content__left .section-grid')

    if (list.length === 0) {
      list = document.querySelectorAll('.main-content__left .custom-push-list')
    }

    isCompact = !!document.querySelectorAll(
      '.main-content__left .grid-component-compact'
    )
    noAds = !!document.querySelector('.custom-push-list[data-no-ads]')

    if (!noAds && list) {
      layoutAds(list, undefined, isCompact ? 2 : undefined)
    }

    if (loadOnScroll) {
      new ScrollObserver()
        .setFetcher(fetcher)
        // .setAddItemCallback(onItemAdded)
        .disableLoader()
        .addItems(list)
        .observe()
    } else {
      showMoreButton('.main-content__left', window.NWS.misc.nextPublishDate)
    }
  }
}
