import type { Maybe } from '@roxen/shared'

export type TrackPurchaseData = {
  source?: string
  path: string
  title: string
  author?: string
}

const cookieName = 'purchase-source'

function setOriginCookie(source: string): void {
  const k5aData: TrackPurchaseData = {
    path: document.URL,
    source,
    title: document.title,
  }

  if (window.NWS.isArticle) {
    const author = $('meta[property="article:author"]').attr('content')?.trim()

    if (author) {
      k5aData.author = author
    }
  }

  const cookieValue = encodeURIComponent(JSON.stringify(k5aData))
  const date = new Date()
  // Expire in about 5 years
  date.setTime(date.getTime() + 5 * 365 * 24 * 60 * 60 * 1000)
  const expires = '; expires=' + date.toUTCString()
  document.cookie = `${encodeURIComponent(
    cookieName
  )}=${cookieValue}${expires}; path=/`
}

export function unsetOriginCookie(): void {
  document.cookie = `${encodeURIComponent(cookieName)}=; Max-Age=0; path=/`
}

export function getOriginCookie(): Maybe<TrackPurchaseData> {
  return document.cookie
    .split(';')
    .filter((c) => c.includes(`${encodeURIComponent(cookieName)}=`))
    .map((c) => {
      const [, val] = c.trim().split('=')
      if (val) {
        return JSON.parse(decodeURIComponent(val)) as TrackPurchaseData
      }
      return undefined
    })?.[0]
}

export function initTrackPurchase(): void {
  if (document.location.search.includes('source=')) {
    const url = new URL(document.location.href)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (url.pathname === window.NWS.staticConfig?.paywall.purchaseEndpoint) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setOriginCookie(url.searchParams.get('source')!)
      url.searchParams.delete('source')
      history.replaceState(null, document.title, url)
    }
  }

  requestAnimationFrame(() => {
    $('[data-purchase-source]').on('click', (e) => {
      if (e.currentTarget.dataset.purchaseSource) {
        setOriginCookie(e.currentTarget.dataset.purchaseSource)
      }
    })
  })

  unsetOriginCookie()
}
