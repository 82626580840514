import { check } from '@roxen/shared'
import $ from 'jquery'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface JQuery<TElement = HTMLElement> {
    sendForm(options?: SendFormOptions): this
  }
}

interface Response {
  ok: boolean
}

interface ResponseOk extends Response {
  data: string
}

interface ResponseError extends Response {
  error: string
}

export interface SendFormOptionsText {
  formWillDisplayAgain?: string
  sending?: string
  sent?: string
}

export interface SendFormOptions {
  showErrorScreenDelay?: number
  text?: SendFormOptionsText
}

class SendForm {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static _jqueryInterface(options?: SendFormOptions): unknown {
    return (this as unknown as JQuery).each(function () {
      // eslint-disable-next-line @typescript-eslint/no-invalid-this
      new SendForm(this, options)
    })
  }

  private readonly base: JQuery<HTMLElement>
  private readonly options: SendFormOptions
  private readonly defaultOptions: SendFormOptions = {
    showErrorScreenDelay: 8000,
  }

  private readonly defaultTexts: SendFormOptionsText = {
    formWillDisplayAgain: '<p>The form will be displayed again soon</p>',
    sending: 'Sending...',
    sent: 'Sent ✓',
  }

  public constructor(base: HTMLElement, options?: SendFormOptions) {
    this.base = $(base)
    this.options = options ?? this.defaultOptions
    this.init()
  }

  private init(): void {
    this.dataToggleEnableForm()
    this.dataToggleDisableInput()
    this.dataSlideToggle()
    this.enableSendForm()
  }

  private dataToggleEnableForm(): void {
    this.base.find('[data-toggle-enable-form]').on('change', (e) => {
      const $el = $(e.target)
      const tgt = $($el.data('toggle-enable-form'))

      if (tgt) {
        if (tgt.hasClass('d-none')) {
          tgt.hide().removeClass('d-none')
        }

        if ($el.is(':checked')) {
          tgt.slideDown()
          this.enableForm(tgt, false)
        } else {
          tgt.slideUp()
          this.disableForm(tgt)
        }
      } else {
        console.warn(`No toggle target found in`, $el)
      }
    })
  }

  private dataToggleDisableInput(): void {
    this.base.find('[data-toggle-disable-input]').on('change', (e) => {
      const $el = $(e.target)
      const tmp = $el.data('toggle-disable-input') as string | undefined

      if (tmp) {
        const tgts = tmp.split(',').map((s) => $(`[name="${s.trim()}"]`))

        tgts.forEach((inp) => {
          inp.prop('disabled', !inp.is(':disabled'))
        })
      }
    })
  }

  private dataSlideToggle(): void {
    this.base.find('[data-slide-toggle]').on('change', (e) => {
      const $el = $(e.target)
      const tgt = $($el.data('slide-toggle'))

      if (tgt.length) {
        if (tgt.css('display') === 'none') {
          tgt.slideDown()
        } else {
          tgt.slideUp()
        }
      } else {
        console.warn(`No toggle target found in`, $el)
      }
    })
  }

  private enableSendForm(): void {
    this.base.submit(async (e) => {
      e.preventDefault()
      const frm = $(e.target) as JQuery<HTMLFormElement>
      const url = frm.attr('action')
      const dataToSend = frm.serializeArray()

      if (url) {
        const ol = this.formOverlay(this.base.closest('.sendform__wrapper'))
        this.disableForm(frm)

        let top = 100
        const ot = frm.offset()

        if (ot) {
          top = ot.top - 80
        }

        $('html,body').animate({ scrollTop: top })

        try {
          const resp: ResponseOk | ResponseError = await this.post({
            url,
            contentType: 'application/json',
            data: JSON.stringify(dataToSend),
            cache: false,
            processData: false,
            dataType: 'json',
          })

          if (this.isResponseOk(resp)) {
            ol.addClass('sendform__overlay-ok')
            ol.find('.sendform__overlay-contents').html(resp.data)
          } else {
            throw new Error(`Response was not ok: ${resp.error}`)
          }

          setTimeout(() => {
            ol.fadeOut()
            const okspan = $(
              `<span class="sendform__okmessage">${this.t('sent')}<span>`
            )
            frm.find('.form-submit').parent().append(okspan)
          }, this.o('showErrorScreenDelay'))
        } catch (err: unknown) {
          check.assertError<Error & { responseJSON?: ResponseError }>(err)
          let message: string
          const xerr = err.responseJSON

          if (xerr) {
            message = xerr.error
          } else {
            message = err.message
          }

          ol.addClass('sendform__overlay-error')
          ol.find('.sendform__overlay-contents').html(
            `${message}${this.t('formWillDisplayAgain')}`
          )

          setTimeout(() => {
            ol.fadeOut(() => ol.remove())
            this.enableForm(frm)
          }, 10000)
        }
      }

      return false
    })
  }

  private disableForm(frm: JQuery<HTMLElement>): void {
    frm.find('input,textarea,select,button').each((_, el) => {
      const $el = $(el)

      if (!$el.prop('disabled')) {
        $(el).prop('disabled', true)
        $(el).attr('sendform-disabled', 'true')
      }
    })
  }

  private enableForm(
    frm: JQuery<HTMLElement>,
    requireSendFormDisabled = true
  ): void {
    frm.find('input,textarea,select,button').each((_, el) => {
      const $el = $(el)
      if ($el.attr('sendform-disabled') || !requireSendFormDisabled) {
        $el.prop('disabled', false)
        $el.removeAttr('sendform-disabled')
      }
    })
  }

  private formOverlay(tgt: JQuery<HTMLElement>): JQuery<HTMLDivElement> {
    const ol = $('<div>')
      .addClass('sendform__overlay')
      .hide()
      .fadeIn() as JQuery<HTMLDivElement>

    const conts = $('<div>')
      .addClass('sendform__overlay-contents')
      .html(`<h1 class="sendform__overlay-sending">${this.t('sending')}</h1>`)

    ol.append(conts)
    tgt.append(ol)

    return ol
  }

  private isResponseOk(r: unknown): r is ResponseOk {
    return (
      (typeof r === 'object' &&
        r !== null &&
        'ok' in r &&
        (r as Response).ok) ||
      false
    )
  }

  private async post(
    options: JQuery.UrlAjaxSettings
  ): Promise<ResponseOk | ResponseError> {
    return new Promise<ResponseOk | ResponseError>((resolve, reject) => {
      $.post(options)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  }

  private o<K extends keyof SendFormOptions>(k: K): SendFormOptions[K] {
    return this.options[k] ?? this.defaultOptions[k]
  }

  private t<K extends keyof SendFormOptionsText>(k: K): SendFormOptionsText[K] {
    return this.options.text?.[k] ?? this.defaultTexts[k]
  }
}

export function installSendFormPlugin(): void {
  const NAME = 'sendForm'
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const JqueryNoConflict = $.prototype[NAME]

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  $.prototype[NAME] = SendForm._jqueryInterface
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  $.prototype[NAME].Constructor = SendForm
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  $.prototype[NAME].noConflict = (): unknown => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    $.prototype[NAME].noConflict = JqueryNoConflict
    return SendForm._jqueryInterface
  }
}
