import { ResponsiveAd } from '@roxen/nws-public/dist/js/nws/responsive-ad'
import type { Maybe } from '@roxen/shared'

/*
  Showing an ad involves:

  1. Layout ad (create ad html)
  2. Render ad
    2.1 Clear ad
    2.2 Load ad
*/

declare global {
  interface Window {
    adn: { calls: VoidFunction[]; request: (params: unknown) => void }
  }
}

interface MediaQueryListResultOnly {
  matches: boolean
}

interface LoadAdsOptions {
  selector: string
  container?: HTMLElement
}

interface ClearAdsOptions {
  selector: string
  container?: HTMLElement
}

interface RenderAdsOptions {
  area: RenderAdsArea[] | 'all'
  container?: HTMLElement
}

type RenderAdsArea =
  | 'main-left'
  | 'main-middle'
  | 'main-right'
  | 'floaters'
  | 'top'
  | 'bottom'
  | 'feed'

const delays = [
  0, 100, 100, 100, 100, 100, 200, 200, 200, 200, 200, 300, 300, 300, 400, 500,
]

/*
  - ?__ads-debug=enable: Enables debug mode persistently via local storage setting
  - ?__ads-debug=disable: Disables debug mode persistently via local storage setting
  - ?__ads-debug=yes: Enables debug mode regardless of local storage setting
  - ?__ads-debug=no: Disables debug mode regardless of local storage setting
*/
function setDebugMode(): void {
  if (window.location.search.includes('__ads-debug=enable')) {
    localStorage.setItem('ads-debug', '')
  } else if (window.location.search.includes('__ads-debug=disable')) {
    localStorage.removeItem('ads-debug')
    localStorage.removeItem('ads-debug-load')
  }
}

function isDebugMode(): boolean {
  if (window.location.search.includes('__ads-debug=yes')) {
    return true
  } else if (window.location.search.includes('__ads-debug=no')) {
    return false
  } else {
    return window.localStorage.getItem('ads-debug') !== null
  }
}

function isLoadDebug(): boolean {
  if (window.location.search.includes('__ads-debug=load')) {
    localStorage.setItem('ads-debug-load', '1')
    return true
  } else if (localStorage.getItem('ads-debug-load')) {
    return true
  }

  return false
}

function debugGetContainerName(container: Maybe<HTMLElement>): string {
  if (!container) {
    return 'document'
  }

  if (container.id?.length) {
    return `#${container.id}`
  }

  if (container.classList.length) {
    return container.classList
      .toString()
      .split(',')
      .map((c) => `.${c.trim()}`)
      .join(' ')
  }

  return container.tagName
}

function matchMedia(query: string): MediaQueryList | MediaQueryListResultOnly {
  if (!window.matchMedia?.('only all').matches) {
    return { matches: true }
  }

  return window.matchMedia(query)
}

function adIsHidden(el: HTMLElement): boolean {
  return el.style.display === 'none'
}

function adIsLoaded(el: HTMLElement): boolean {
  return el.style.height !== '' && el.style.height !== '0px'
}

function getAttempts(el: HTMLElement): number {
  const dataAttempts = el.getAttribute('data-attempts')
  let attempts: number

  if (dataAttempts) {
    attempts = parseInt(dataAttempts, 10)
  } else {
    attempts = 0
  }

  el.setAttribute('data-attempts', (attempts + 1).toString())

  return attempts
}

function getNextDelay(el: HTMLElement): number | undefined {
  const attempts = getAttempts(el)

  if (attempts < delays.length) {
    return delays[attempts]
  } else {
    return undefined
  }
}

function makeResponsiveWhenLoaded(el: HTMLElement): void {
  if (adIsHidden(el)) {
    return
  } else if (adIsLoaded(el)) {
    if (el.parentNode) {
      // In some cases the responsive ad calculations won't work without this.
      ;(el.parentNode as HTMLElement).style.width = '100%'
      new ResponsiveAd(el.parentNode as HTMLElement)

      // To fix when ads with sizes not matching the slot size is loaded
      // [BYGG-424]
      if (el.classList.contains('adnuntius-front-mobile')) {
        const initHeight = el.clientHeight
        setTimeout(() => {
          if (el.clientHeight !== initHeight) {
            new ResponsiveAd(el.parentNode as HTMLElement)
          }
        }, 50)
      }
    }
  } else {
    const delay = getNextDelay(el)

    if (delay !== undefined) {
      window.setTimeout(makeResponsiveWhenLoaded, delay, el)
    }
  }
}

function tmpDisplayAllElements(elems: HTMLElement[]): void {
  elems.forEach((el) => {
    const initdisplayType = el.style.display

    if (initdisplayType === 'none') {
      const w = el.dataset.w ?? '100'
      const h = el.dataset.h ?? '50'
      el.dataset.initWidth = el.style.width
      el.dataset.initHeight = el.style.height
      el.style.width = `${w}px`
      el.style.height = `${h}px`
      el.style.display = 'block'
    }

    el.dataset.initDisplayType = initdisplayType
  })
}

function tmpResetDisplayType(elems: HTMLElement[]): void {
  elems.forEach((el) => {
    const init = el.dataset.initDisplayType
    if (init && !el.dataset.initNoReset) {
      el.style.display = init
      el.style.width = el.dataset.initWidth ?? ''
      el.style.height = el.dataset.initHeight ?? ''
    }
  })
}

function isInViewport(el: HTMLElement): boolean {
  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left >= 0 &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export function loadAds({ selector, container }: LoadAdsOptions): void {
  console.timeLog(
    'ad:load',
    `loadAds({ selector: ${selector}, container: ${debugGetContainerName(
      container
    )} }) fired`
  )

  const adElements = Array.from(
    (container ?? document).querySelectorAll<HTMLElement>(selector) ?? []
  )

  if (isLoadDebug()) {
    adElements.forEach((el) => {
      const w = el.dataset.w ?? '100'
      const h = el.dataset.h ?? '50'

      el.dataset.initNoReset = 'true'
      el.style.width = `${w}px`
      el.style.height = `${h}px`
      el.style.display = 'block'
      el.style.outline = '3px dotted darkorange'
    })
  }

  // Fetch elements by selector, use element attributes id, data-h, data-w as
  // parameters for Adnuntius call.
  window.adn.calls.push(function () {
    console.timeLog('ad:load', 'window.adn.calls queue fired')

    const elements: HTMLElement[] = adElements.map((e) => {
      if (isLoadDebug()) {
        e.style.outline = '3px dashed darkorange'
      }

      // This is a hack due to the lack of a callback when the ad has been loaded.
      // We listen to ad container mutation and make guesses about when the ad
      // iframe has been loaded so we can measure its size properly.
      const mutationObserver = new MutationObserver((mutations) => {
        const m = mutations[0]
        if (!m) {
          mutationObserver.disconnect()
          return
        }

        const elem = m.target as HTMLDivElement
        const addedNode = m.addedNodes?.[0]

        if (addedNode?.nodeName === 'IFRAME') {
          console.timeLog(
            'ad:load',
            `add DOMContentLoaded listener on ${elem.id}`
          )

          const doc = (addedNode as HTMLIFrameElement).contentDocument

          if (doc?.readyState === 'complete') {
            const adImg = doc.querySelector('img')

            if (adImg) {
              adImg.addEventListener('load', () => {
                console.timeLog(
                  'ad:load',
                  `IFRAME loaded from cache for ${elem.id}`
                )

                if (isLoadDebug()) {
                  elem.style.outline = '3px solid darkgreen'
                }
              })
            } else {
              console.timeLog(
                'ad:load',
                `Ad slot loaded from cache without ad image`
              )

              if (isLoadDebug()) {
                elem.style.outlineColor = 'red'
              }
            }
          } else {
            doc?.addEventListener(
              'DOMContentLoaded',
              () => {
                const adImg = doc.querySelector('img')

                if (adImg) {
                  adImg.addEventListener('load', () => {
                    console.timeLog(
                      'ad:load',
                      `IFRAME loaded from network for ${elem.id}`
                    )
                    if (isLoadDebug()) {
                      elem.style.outline = '3px solid darkorange'
                    }
                  })
                } else {
                  console.timeLog(
                    'ad:load',
                    `Ad slot loaded from network without ad image (${elem.id})`
                  )
                  if (isLoadDebug()) {
                    elem.style.outlineColor = 'red'
                  }
                }
              },
              { once: true }
            )
          }

          mutationObserver.disconnect()
        }

        if (
          elem.classList.contains('ad-responsive') &&
          addedNode?.nodeName === 'IFRAME'
        ) {
          makeResponsiveWhenLoaded(m.target as HTMLElement)
        }
      })

      mutationObserver.observe(e, { subtree: false, childList: true })

      return e
    })

    tmpDisplayAllElements(elements)
    const isInViewportElems = elements.filter(isInViewport)
    tmpResetDisplayType(elements)

    if (isDebugMode()) {
      elements.map((el) => renderDebugAd(el))
    } else if (elements.length) {
      console.timeLog(
        'ad:load',
        `window.adn.request dispatched (${elements.length} ads, ${isInViewportElems.length} in viewport)`
      )

      window.adn.request({
        adUnits: elements.map((e) => {
          if (!e.id) {
            e.id = e.dataset.id ?? ''
          }

          return {
            auId: e.id.replace('adn-', ''),
            auW: e.dataset.w,
            auH: e.dataset.h,
          }
        }),
      })
    } else {
      console.timeLog('ad:load', 'No adUnits found for selector in container')
    }
  })
}

export function clearAds({ selector, container }: ClearAdsOptions): void {
  console.timeLog(
    'ad:load',
    `clearAds({ selector: ${selector}, container: ${debugGetContainerName(
      container
    )} }) fired`
  )
  // console.log(`clearAds(): ${container?.tagName ?? 'document'} => ${selector}`)
  ;(container ?? document).querySelectorAll(selector).forEach((el) => {
    $(el)
      .empty()
      .hide()
      .removeAttr('data-attempts')
      .parent('.ad-wrapper')
      .removeAttr('style')
  })
  console.timeLog('ad:load', 'clearAds() done')
}

export function getAdsSelectors(currentDeviceOnly?: boolean): string[] {
  const mobileSelectors = ['.adnuntius-mobile', '.adnuntius-front-mobile']
  const desktopSelectors = ['.adnuntius-desktop']

  if (currentDeviceOnly) {
    if (window.NWS.adDevice() === 'mobile') {
      return mobileSelectors
    } else {
      return desktopSelectors
    }
  } else {
    return mobileSelectors.concat(desktopSelectors)
  }
}

function deviceChanged(): void {
  clearAds({ selector: getAdsSelectors().join(',') })
  loadAds({ selector: getAdsSelectors(true).join(',') })
}

function registerListener(callback: () => void): void {
  const desktopMinWidth = window.NWS.getCssPropertyValue('--breakpoint-md')
  const mediaQueryList = matchMedia(`(min-width: ${desktopMinWidth}`)

  if (mediaQueryList instanceof MediaQueryList) {
    mediaQueryList.addListener(callback)
  }
}

function renderDebugAd(el: HTMLElement): void {
  const id = $(el).attr('id')
  const pos = id ? id.replace('adn-', '') : 'Ad id missing'
  const dataH = parseInt($(el).attr('data-h') ?? '0', 10)
  const dataW = parseInt($(el).attr('data-w') ?? '0', 10)
  const height = dataH === 0 || isNaN(dataH) ? '100' : dataH
  const width = dataW === 0 || isNaN(dataW) ? '100' : dataW
  const date = new Date()
  const idMsg = `Id: ${pos}`
  const whenMsg = `At: ${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
  const intitiallyInViewport = `Init in viewport: ${isInViewport(el)}`

  $(el)
    .css('height', height)
    .css('width', width)
    .addClass('debug')
    .attr('title', `${idMsg}\n${whenMsg}\n${intitiallyInViewport}`)
    .append(`${idMsg}<br/>${whenMsg}<br/>${intitiallyInViewport}`)
    .show()
}

export function renderAds({ area, container }: RenderAdsOptions): void {
  console.timeLog('ad:load', 'renderAds() fired')
  const selectors: string[] = []

  if (area === 'all') {
    selectors.push('')
  } else {
    for (const a of area) {
      switch (a) {
        case 'top': {
          selectors.push('.main-content__top')
          break
        }
        case 'bottom': {
          selectors.push('.main-content__bottom')
          break
        }
        case 'floaters': {
          selectors.push('.floating-ads__left')
          selectors.push('.floating-ads__right')
          break
        }
        case 'main-left': {
          selectors.push('.main-content__left')
          break
        }
        case 'main-middle': {
          selectors.push('.main-content__middle')
          break
        }
        case 'main-right': {
          selectors.push('.main-content__right')
          break
        }
        case 'feed': {
          selectors.push('.front')
          break
        }
        default: {
          break
        }
      }
    }
  }

  if (selectors.length > 0) {
    const fullSelectors: string[] = []

    for (const adsSelector of getAdsSelectors(true)) {
      fullSelectors.push(
        selectors
          .map((sel) => `${sel.length === 0 ? '' : `${sel} `}${adsSelector}`)
          .join(',')
      )
    }

    const selector = fullSelectors.join(',')

    clearAds({ selector, container })
    loadAds({ selector, container })
  }
}

export const initAds = (): void => {
  console.timeLog('ad:load', 'initAds() fired')

  setDebugMode()
  registerListener(deviceChanged)
  renderAds({ area: 'all' })

  window.NWS.onContentInserted((container?: HTMLElement) => {
    console.timeLog(
      'ad:load',
      `onContentInserted callback for ${container?.tagName ?? '<none>'}`
    )
    renderAds({ area: 'all', container })
  })
}
