/* eslint-disable no-invalid-this */
import $ from 'jquery'

import type { CalendarDate } from 'calendar-base'
import { Calendar as CalendarBase } from 'calendar-base'
import type {
  CalendarOptions,
  OtherOption,
  ResolvedOptions,
  CalendarEvent,
  CalendarEventEntry,
  ClickEventResult,
} from './types'

export class Calendar {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static _jqueryInterface(
    options?: CalendarOptions | OtherOption
  ): unknown {
    const self = this as unknown as JQuery

    if (typeof options === 'string') {
      const cals: Calendar[] = []

      self.each(function () {
        // eslint-disable-next-line @typescript-eslint/no-invalid-this
        const cal = $(this).prop('calendar')

        if (cal) {
          cals.push(cal)
        }
      })

      return cals
    } else {
      return self.each(function () {
        // eslint-disable-next-line @typescript-eslint/no-invalid-this
        new Calendar(this, options)
      })
    }
  }

  /**
   * Converts a `Date` into a `CalendarDate` object
   * @param date
   */
  public static dateToCalendarDate(date: Date): CalendarDate {
    const cd: CalendarDate = {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth(),
      day: date.getUTCDate(),
      weekDay: date.getUTCDay(),
    }

    cd.weekNumber = CalendarBase.calculateWeekNumber(cd)

    return cd
  }

  /**
   * Converts a `Date | CalendarDate` into an ISO-8601 date string
   * @param d
   */
  public static calendarDateToYmdString(d: CalendarDate | Date): string {
    if (d instanceof Date) {
      d = this.dateToCalendarDate(d)
    }

    return `${d.year}-${d.month + 1 < 10 ? `0${d.month + 1}` : d.month + 1}-${
      d.day
    }`
  }

  /**
   * Converts a `CalendarDate` into a UTC `Date`
   * @param d
   */
  public static calendarDateToDate(d: CalendarDate): Date {
    return new Date(Date.UTC(d.year, d.month, d.day))
  }

  /** The JQuery HTML element containing the calendar widget */
  public readonly base: JQuery<HTMLElement>
  /** The options. Default options merged with passed options */
  public readonly options: ResolvedOptions

  private readonly calendar: CalendarBase
  private readonly defaultOptions: CalendarOptions = {
    weekStart: 1,
    weekDays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    months: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    nextMonth: 'Next month',
    prevMonth: 'Previous month',
    nextYear: 'Next year',
    prevYear: 'Previous year',
  }

  private readonly table: JQuery<HTMLDivElement>
  private readonly body: JQuery<HTMLDivElement>
  private readonly currMonth!: JQuery<HTMLElement>
  private readonly currYear!: JQuery<HTMLElement>
  private currDate!: CalendarDate
  private prevRenderDate: CalendarDate | undefined
  private today!: Date

  constructor(el: HTMLElement, options?: CalendarOptions) {
    this.initToday()
    this.base = $(el)
    this.base.prop('calendar', this)
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    this.options = {
      ...this.defaultOptions,
      ...(options ?? {}),
    } as ResolvedOptions
    this.calendar = new CalendarBase(options)

    const o = this.options

    const tmpl = `
    <div class="cw-table">
      <div class="cw-head">
        <div class="cw-row cw-row__buttons">
          <div class="cw-cell cw-cell__left-align">
            ${
              o.prevYear === false
                ? ''
                : `<button class="cw-button cw-button__prev cw-button__prev-year" title="${o.prevYear}"></button>`
            }
            <button class="cw-button cw-button__prev cw-button__prev-month" title="${
              o.prevMonth
            }"></button>
          </div>

          <div class="cw-cell cw-curr cw-cell__center-align">
            <span class="cw-curr cw-curr__month"></span>
            <span class="cw-curr cw-curr__year"></span>
          </div>

          <div class="cw-cell cw-cell__right-align">
            <button class="cw-button cw-button__next cw-button__next-month" title="${
              o.nextMonth
            }"></button>
            ${
              o.nextYear === false
                ? ''
                : `<button class="cw-button cw-button__prev cw-button__next-year" title="${o.nextYear}"></button>`
            }
          </div>
        </div>
        <div class="cw-row cw-row__weekdays">${o.weekDays
          .map(
            (d) =>
              `<span class="cw-cell cw-cell__date cw-cell__center-align">${d}</span>`
          )
          .join('')}</div>
      </div>
      <div class="cw-body"></div>
    </div>
    `

    this.table = $(tmpl)
    this.body = this.table.find('.cw-body') as JQuery<HTMLDivElement>
    this.currYear = this.table.find('.cw-curr__year')
    this.currMonth = this.table.find('.cw-curr__month')
    this.base.empty().append(this.table)
    this.initClickHandlers()
    this.renderCalendar(this.options.startDate ?? new Date())
  }

  /**
   * Add events. Note that this will not check for dulicate events.
   * @param events
   */
  public addEvents(events: CalendarEvent[]): this {
    const evs = this.o('events')

    if (evs) {
      this.options.events = [...evs, ...events]
    } else {
      this.options.events = events
    }

    this.renderCalendar(this.currDate)

    return this
  }

  /**
   * Returns the events for the given date
   * @param d
   */
  public getEvents(d: CalendarDate | Date): CalendarEventEntry[] | undefined {
    if (d instanceof Date) {
      d = Calendar.dateToCalendarDate(d)
    }

    const e = this.o('events')

    if (!e?.length) {
      return undefined
    }

    const f = e.find(
      (ev) =>
        CalendarBase.compare(
          d as CalendarDate,
          Calendar.dateToCalendarDate(ev.date)
        ) === 0
    )

    return f?.events
  }

  private initToday(): void {
    const d = new Date()
    this.today = new Date(
      Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate())
    )
  }

  private initClickHandlers(): void {
    this.table
      .find('.cw-head button')
      .on('click', this.changeMonthOrYear.bind(this))
    this.body.on('click', this.bodyClick.bind(this))
  }

  private bodyClick(e: JQuery.ClickEvent): void {
    const ev = this.getClickEvent($(e.target))
    const cb = this.o('onclick')

    if (cb && ev) {
      cb(this, ev)
    }
  }

  private getClickEvent(el: JQuery<HTMLElement>): ClickEventResult | undefined {
    if (!el.hasClass('cw-cell')) {
      return undefined
    }

    const dp = el.attr('date')

    if (!dp) {
      return undefined
    }

    const d = new Date(Date.parse(dp))

    return {
      date: d,
      isCurrentDate: el.hasClass('cw-cell__today'),
      isHistoricDate: el.hasClass('cw-cell__historic'),
      events: this.getEvents(d),
    }
  }

  private renderCalendar(from: CalendarDate | Date): void {
    if (from instanceof Date) {
      from = Calendar.dateToCalendarDate(from)
    }

    // Don't emit when re-rendering
    const noEmitRenderEvent =
      this.prevRenderDate &&
      CalendarBase.compare(this.prevRenderDate, from) === 0

    this.currDate = from
    this.setCurrText()

    const cells = this.calendar
      .getCalendar(from.year, from.month)
      .map((d) => {
        const classes = ['cw-cell', 'cw-cell__date']

        if (d === false) {
          classes.push('cw-cell__other-month')
          return `<span class="${classes.join(' ')}"></span>`
        } else {
          const ev = this.getEvents(d)

          if (ev) {
            classes.push('cw-cell__events')
          }

          if (this.isToday(d)) {
            classes.push('cw-cell__today')
          }

          if (this.isHistoric(d)) {
            classes.push('cw-cell__historic')
          }

          const dateProp = Calendar.calendarDateToYmdString(d)

          return `<span class="${classes.join(' ')}" date="${dateProp}">${
            d.day
          }</span>`
        }
      })
      .join('')

    this.body.empty().append(`<div class="cw-row cw-row__dates">${cells}</div>`)

    this.prevRenderDate = from

    if (!noEmitRenderEvent) {
      const cb = this.o('onchange')

      if (cb) {
        cb(this, from)
      }
    }
  }

  private isToday(d: CalendarDate): boolean {
    return (
      d.year === this.today.getUTCFullYear() &&
      d.month === this.today.getUTCMonth() &&
      d.day === this.today.getUTCDate()
    )
  }

  private isHistoric(d: CalendarDate): boolean {
    const dd = Calendar.calendarDateToDate(d)
    return dd < this.today
  }

  private setCurrText(): void {
    this.currYear.text(this.currDate.year)
    this.currMonth.text(this.o('months')[this.currDate.month])
  }

  private o<K extends keyof CalendarOptions>(k: K): ResolvedOptions[K] {
    return this.options[k]
  }

  private changeMonthOrYear(e: JQuery.ClickEvent): void {
    const cd = Calendar.calendarDateToDate(this.currDate)
    const t = $(e.target)

    if (t.hasClass('cw-button__prev-month')) {
      cd.setUTCMonth(cd.getUTCMonth() - 1)
    } else if (t.hasClass('cw-button__prev-year')) {
      cd.setUTCFullYear(cd.getUTCFullYear() - 1)
    } else if (t.hasClass('cw-button__next-month')) {
      cd.setUTCMonth(cd.getUTCMonth() + 1)
    } else if (t.hasClass('cw-button__next-year')) {
      cd.setUTCFullYear(cd.getUTCFullYear() + 1)
    }

    this.renderCalendar(cd)
  }
}

export function installCalendarPlugin(): void {
  const NAME = 'calendar'
  const $p = $.prototype
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const JqueryNoConflict = $p[NAME]

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  $p[NAME] = Calendar._jqueryInterface
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  $p[NAME].Constructor = Calendar
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  $p[NAME].noConflict = (): unknown => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    $p[NAME].noConflict = JqueryNoConflict

    return Calendar._jqueryInterface
  }
}
