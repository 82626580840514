export function initModalHack(): void {
  let scrollTop = 0
  $('.modal.nativead-image-popup').on('show.bs.modal', function (_e) {
    scrollTop = $(window).scrollTop() ?? 0
  })
  $('.modal.nativead-image-popup').on('hidden.bs.modal', function (_e) {
    $(window).scrollTop(scrollTop)
  })
  $('.modal.article-image-popup').on('show.bs.modal', function (_e) {
    scrollTop = $(window).scrollTop() ?? 0
  })
  $('.modal.article-image-popup').on('hidden.bs.modal', function (_e) {
    $(window).scrollTop(scrollTop)
  })
}
