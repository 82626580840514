import $ from 'jquery'
import { onLoad } from './onload'
import { CustomerNws } from './customer-nws'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface NWSType extends CustomerNws {}

  interface Window {
    NWS: NWSType
  }
}

export const initNWS = (): void => {
  console.time('ad:load')

  window.NWS =
    window.NWS || new CustomerNws(!!document.location.href.match(/[?&]debug/))

  $(() => {
    window.NWS.init()
    onLoad()
  })
}
