/* eslint-disable @typescript-eslint/ban-ts-comment */
// NOTE: There's too much hazzle fixing typings and stuff for Galleria
//       (albeit there are exiting typings), hence the little sprinklings
//        of @ts-ignores in this file

import type { PlainObject } from '@roxen/shared'

export function initGalleria(): void {
  if ($('.galleria').length) {
    // @ts-expect-error
    if (!window.Galleria) {
      console.warn(`No Galleria.js loaded`)
    } else {
      let initialImage = 0
      if (window.location.search?.includes('image=')) {
        try {
          const u = new URL(window.location.toString())
          initialImage = parseInt(u.searchParams.get('image') as string, 10)
        } catch (err: unknown) {
          console.error('Error:', err)
        }
      }

      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      window.Galleria.run('.galleria', {
        theme: 'azur',
        imageCrop: false,
        fullscreenCrop: false,
        canvas: true,
        show: initialImage,
        autoplay: false,
        height: 0.8,
        extend: function () {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const gallery = this

          $('.galleria-play').on('click', (e) => {
            e.preventDefault()
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            gallery.play(3000)
            return false
          })
        },
        _locale: {
          play: 'Vis billedkarusell',
          pause: 'Stopp bildekarusell',
          enter_fullscreen: 'Gå til fullskjerm',
          exit_fullscreen: 'Forlat fullskjerm',
          showing_image: 'Viser bilde %s av %s',
          show_captions: 'Vis bildetekst',
          hide_captions: 'Skjul bildetekst',
          next: 'Neste bilde',
          prev: 'Forrige bilde',
        },
      })

      let isInited = false
      const currUrl = new URL(window.location.toString())

      const gt = function (..._args: unknown[]): void {
        if (window.gtag) {
          // eslint-disable-next-line prefer-rest-params
          window.gtag(arguments)
        } else if (window.NWSDataLayer) {
          // eslint-disable-next-line prefer-rest-params
          window.NWSDataLayer.push(arguments)
        }
      }

      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      window.Galleria.ready(function (
        this: PlainObject,
        _opts: PlainObject
      ): void {
        window.onpopstate = (e: PopStateEvent): void => {
          _opts._isPopState = true

          if (e.state) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            this.show(e.state.index)
          } else {
            this.show(0)
          }
        }

        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        window.Galleria.on('image', (e: Event) => {
          const _e = e as PlainObject
          e.preventDefault()

          if (isInited) {
            if (!_opts._isPopState) {
              currUrl.searchParams.set('image', _e.index)

              window.history.pushState(
                { index: _e.index },
                `${document.title}: Bilde ${_e.index}`,
                currUrl.toString()
              )

              window.requestAnimationFrame(() => {
                gt('event', 'image_change', {
                  event_category: 'Bildegalleri',
                  event_label: currUrl.toString(),
                })
              })
            } else {
              _opts._isPopState = false
            }
          } else {
            isInited = true
          }
        })
      })
    }
  }
}
