export function initSendForm(): void {
  window.NWS.onContentInserted((container?: HTMLElement) => {
    $(container ?? document.body)
      .find('.sendform__form')
      .sendForm({
        text: {
          formWillDisplayAgain: 'Skjemaet vises snart igjen',
          sending: 'Sende...',
          sent: 'Sendt ✓',
        },
      })
  }, true)
}
