import { formatAd } from 'nws-site-shared'

export interface AdConfig {
  id: string
  mobile: boolean
  desktop: boolean
  width: number
  height: number
  groupWith?: AdConfig[]
}

// This was sectionPageAdsLayout
const defaultPageAdsLayout: AdConfig[] = [
  {
    id: '00000000000413e2',
    desktop: true,
    mobile: true,
    width: 600,
    height: 0,
  },
  {
    id: '00000000000575fa',
    desktop: true,
    mobile: true,
    width: 150,
    height: 0,
    groupWith: [
      {
        id: '0000000000057647',
        desktop: true,
        mobile: true,
        width: 150,
        height: 0,
      },
      {
        id: '0000000000057651',
        desktop: true,
        mobile: true,
        width: 150,
        height: 0,
      },
    ],
  },
  {
    id: '00000000000413e6',
    desktop: true,
    mobile: true,
    width: 600,
    height: 0,
  },
  {
    id: '000000000005765e',
    desktop: true,
    mobile: true,
    width: 150,
    height: 0,
    groupWith: [
      {
        id: '0000000000057661',
        desktop: true,
        mobile: true,
        width: 150,
        height: 0,
      },
      {
        id: '0000000000057669',
        desktop: true,
        mobile: true,
        width: 150,
        height: 0,
      },
    ],
  },
  {
    id: '000000000004140b',
    desktop: true,
    mobile: true,
    width: 600,
    height: 0,
  },
  {
    id: '00000000000575bf',
    desktop: true,
    mobile: true,
    width: 600,
    height: 0,
  },
  {
    id: '00000000000575c1',
    desktop: true,
    mobile: true,
    width: 600,
    height: 0,
  },
  {
    id: '00000000000575c7',
    desktop: true,
    mobile: true,
    width: 600,
    height: 0,
  },
  {
    id: '00000000000575c9',
    desktop: true,
    mobile: true,
    width: 600,
    height: 0,
  },
  {
    id: '00000000000576c2',
    desktop: true,
    mobile: true,
    width: 600,
    height: 0,
  },
]

let carouselAdCounter = 0

export function layoutAdsFromConfig(
  idx: number,
  adsLayout = defaultPageAdsLayout
): string | undefined {
  if (idx > adsLayout.length - 1) {
    return
  }

  const cfg = adsLayout[idx]

  if (cfg) {
    const adsHtml: string[] = []
    const cfgs = [cfg].concat(cfg.groupWith ?? [])
    const grouped = cfgs.length > 1

    for (const c of cfgs) {
      const adClasses = []

      if (c.desktop) {
        adClasses.push('adnuntius-desktop')
      }

      if (c.mobile) {
        adClasses.push('adnuntius-mobile ad-responsive')
      }

      adsHtml.push(
        formatAd({
          id: c.id,
          width: c.width,
          height: c.height,
          adClass: adClasses.join(' '),
        })
      )
    }

    const html = adsHtml.join('')

    if (grouped) {
      return `<div class='ad-border'><div class='ad-group ad-group--${adsHtml.length}'>${html}</div></div>`
    } else {
      return `<div class='ad-border'>${html}</div>`
    }
  }
}

/*
  The purpose of this function is to disable ads so they are not loaded again
  if loadAds() is called, but still look the same to the visitor. The purpose
  is to avoid having pages collapse when laying out the same ads again on dynamically
  inserted content.
*/
export function disableAds(container: Element): void {
  container.querySelectorAll('.ad').forEach((node) => {
    $(node).removeAttr('id')
    $(node).removeAttr('class')
    $(node).addClass('ad')
  })
}

export function layoutAds(
  list: NodeListOf<Element>,
  adsConfig?: AdConfig[],
  everyNth = 1
): void {
  const container = list.item(0)?.parentElement

  if (container) {
    disableAds(container)

    if (window.NWS.misc.isBildekarusellerSection) {
      if (list.length > 0) {
        const html = layoutAdsFromConfig(carouselAdCounter, adsConfig)

        if (html) {
          $(Array.from(list)[list.length - 1]).after(html)
        }

        if (carouselAdCounter === defaultPageAdsLayout.length - 1) {
          carouselAdCounter = 0
        } else {
          carouselAdCounter++
        }
      }
    } else if (list.length <= everyNth) {
      const last = list.item(list.length - 1)
      if (last) {
        const html = layoutAdsFromConfig(0, adsConfig)

        if (html) {
          $(last).after(html)
        }
      }
    } else {
      let adCounter = 0
      const modMatch = everyNth > 1 ? everyNth - 1 : 0

      list.forEach((el, counter) => {
        if (counter % everyNth === modMatch) {
          const html = layoutAdsFromConfig(adCounter, adsConfig)

          if (html) {
            $(el).after(html)
          }

          adCounter += 1
        }
      })
    }
  }
}
