import $ from 'jquery'
import type { CalendarDate } from 'calendar-base'
import type { CalendarEvent } from './jq-plugins/calendar'
import { Calendar } from './jq-plugins/calendar'

export function initCalendarWidget(): void {
  function resolvePath(date: Date): string {
    let currPath = document.location.pathname
    currPath = currPath.replace(/(.*)\/\d{4}-\d{2}-\d{2}\/$/, '$1')
    currPath += currPath.endsWith('/') ? '' : '/'

    return `${currPath}${Calendar.calendarDateToYmdString(date)}/`
  }

  function getDateFromPath(): Date | undefined {
    const m = document.location.pathname.match(/.*\/(\d{4}-\d{2}-\d{2})\/$/)

    if (m) {
      return new Date(Date.parse(m[1]))
    }

    return undefined
  }

  function doCalendar(target: JQuery<HTMLElement>): void {
    const isFetched: Record<string, boolean> = {}

    const fetchEvents = async (
      d?: CalendarDate | Date
    ): Promise<CalendarEvent[] | undefined> => {
      if (!d) {
        d = new Date()
      }

      if (d instanceof Date) {
        d = Calendar.dateToCalendarDate(d)
      }

      const key = `${d.year}-${d.month + 1}`

      if (isFetched[key]) {
        return undefined
      }

      const q = {
        year: d.year,
        month: d.month + 1,
      }

      try {
        const res = (await $.ajax({
          url: '/api/site/v1/events',
          method: 'get',
          type: 'json',
          data: q,
        })) as CalendarEvent[]

        if (res) {
          // eslint-disable-next-line require-atomic-updates
          isFetched[key] = true

          return res.map((r) => {
            r.date = new Date(Date.parse(r.date.toString()))
            return r
          })
        }
      } catch (e: unknown) {
        console.error('Error:', e)
      }

      return undefined
    }

    const sd = getDateFromPath()

    target.find('.calendar-widget__calendar').calendar({
      startDate: sd && Calendar.dateToCalendarDate(sd),
      weekStart: 1,
      weekDays: ['Ma', 'Ti', 'On', 'To', 'Fr', 'Lø', 'Sø'],
      months: [
        'Januar',
        'Februar',
        'Mars',
        'April',
        'Mai',
        'Juni',
        'July',
        'August',
        'September',
        'Oktober',
        'November',
        'Desember',
      ],
      nextMonth: 'Neste måned',
      prevMonth: 'Forrige måned',
      prevYear: false,
      nextYear: false,
      events: [],
      onclick(_, res) {
        if (res?.events) {
          document.location.href = resolvePath(res.date)
        }
      },
      async onchange(cal, month) {
        const d = await fetchEvents(month)

        if (d?.length) {
          cal.addEvents(d)
        }
      },
    })
  }

  window.NWS.onContentInserted((container?: HTMLElement) => {
    doCalendar($(container ?? document.body))
  }, true)
}
