// FIXME: Move this to @roxen/nws-public when done

import $ from 'jquery'
import { check } from '@roxen/shared'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface JQuery<TElement = HTMLElement> {
    quiz(options?: QuizzerOptions): this
  }
}

interface QuizResult {
  contestant: Record<string, string>
  answers: string[]
}

interface QuizSendResult {
  ok: boolean
  message: string
}

export interface QuizzerTexts {
  next?: string
  previous?: string
  submit?: string
  sending?: string
}

export interface QuizzerOptions {
  apiEndpoint?: string
  texts?: QuizzerTexts
}

class Quizzer {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static _jqueryInterface(config?: QuizzerOptions): JQuery {
    return (this as unknown as JQuery).each(function () {
      // eslint-disable-next-line @typescript-eslint/no-invalid-this
      new Quizzer(this, config)
    })
  }

  private readonly base: JQuery<HTMLElement>
  private pages: JQuery<HTMLElement> | undefined
  private nextButton: JQuery<HTMLButtonElement> | undefined
  private prevButton: JQuery<HTMLButtonElement> | undefined
  private submitButton: JQuery<HTMLButtonElement> | undefined
  private currPage = 0
  private currPageEl: JQuery<HTMLSpanElement> | undefined
  private readonly options: QuizzerOptions
  private readonly defaultEndpoint = '/api/site/v1/quiz/send/'
  private readonly defaultTexts: QuizzerTexts = {
    submit: 'Submit',
    next: 'Next',
    previous: 'Previous',
    sending: 'Sending',
  }

  constructor(el: HTMLElement, options?: QuizzerOptions) {
    this.options = options ?? {}
    this.base = $(el)
    this.init()
  }

  private init(): void {
    this.pages = this.base.find('.quiz__question')

    if (this.pages.length) {
      this.makeFooter()
      this.hookupSendForm()
      this.pages.each((i, el) => this.makePage(el, i))
    }
  }

  private makePage(el: HTMLElement, i: number): void {
    const $el = $(el)

    if (i > 0) {
      $el.hide()
    }
  }

  private hookupSendForm(): void {
    const ffs = $('.quiz__question-sendform input:required')

    if (ffs.length) {
      const nok: Record<string, boolean> = {}

      // Don't remove the curly braces
      ffs.each((_, el) => {
        nok[(el as HTMLInputElement).name] = false
      })

      ffs.on('input', (e) => {
        const f = e.target as HTMLInputElement
        nok[f.name] = f.validity.valid

        this.submitButton?.prop(
          'disabled',
          !Object.keys(nok).every((k) => nok[k])
        )
      })
    }
  }

  private makeFooter(): void {
    if (this.pages && this.pages.length > 1) {
      const footer = $('<div>').addClass('quiz__footer')

      const buttons = $('<div class="quiz__buttons">')

      this.submitButton = $(`<button>${this.t('submit')}</button>`)
        .prop('disabled', true)
        .addClass('quiz__buttons-button btn btn-sm btn-success')
        .on('click', this.onSubmit.bind(this))
        .hide() as JQuery<HTMLButtonElement>

      const meta = $(
        `<div>
            <span class="quiz__meta-currpage">1</span>
            <span class="quiz__meta-divider">/</span>
            <span class="quiz__meta-totalpages">${this.pages.length}</span>
          </div>`
      ).addClass('quiz__meta')

      footer.append(meta)

      this.currPageEl = meta.find('.quiz__meta-currpage')

      this.prevButton = $(`<button>${this.t('previous')}</button>`)
        .addClass('quiz__buttons-button btn btn-sm btn-secondary')
        .on('click', () => this.onChangePage(-1))
        .hide() as JQuery<HTMLButtonElement>

      this.nextButton = $(`<button>${this.t('next')}</button>`)
        .addClass('quiz__buttons-button btn btn-sm btn-primary')
        .on('click', () => this.onChangePage(1)) as JQuery<HTMLButtonElement>

      buttons
        .append(this.prevButton)
        .append(this.nextButton)
        .append(this.submitButton)

      footer.append(buttons)

      this.base.append(footer)
    }
  }

  private t(key: keyof QuizzerTexts): string {
    return (this.options.texts?.[key] ?? this.defaultTexts[key]) as string
  }

  private setCurrPage(n: number): void {
    if (this.currPageEl?.length) {
      this.currPageEl.text(`${n + 1}`)
    }
  }

  private onChangePage(offset: number): void {
    if (this.pages) {
      const curr = this.pages[this.currPage]
      const hasOn = $(curr).find('input[type=radio]:checked')

      if (!hasOn.length && !(offset < 1)) {
        return
      }

      const next = this.pages[this.currPage + offset]

      if (next) {
        $(curr).hide()
        $(next).show()

        this.currPage += offset
        this.setCurrPage(this.currPage)
      }

      if (this.currPage < 1) {
        this.prevButton?.hide()
      } else {
        this.prevButton?.show()
      }

      if (this.currPage + 1 === this.pages.length) {
        this.nextButton?.hide()
        this.submitButton?.show()
      } else {
        this.nextButton?.show()
        this.submitButton?.hide()
      }
    }
  }

  private showSubmittingPage(): JQuery<HTMLDivElement> {
    const p = this.base
    p.find('input,button').prop('disabled', true)

    const ol = $(`<div><div>${this.t('sending')}...</div></div>`).addClass(
      'quiz-component__overlay'
    ) as JQuery<HTMLDivElement>

    p.append(ol)

    return ol
  }

  private async onSubmit(): Promise<void> {
    const ol = this.showSubmittingPage()
    const v = this.collectValues()

    try {
      const r = (await $.ajax({
        url: this.getEndpoint(),
        data: v,
        dataType: 'json',
        method: 'post',
      })) as QuizSendResult

      if (!r.ok) {
        throw new Error(r.message)
      }

      ol.find('div').html(r.message)
    } catch (e: unknown) {
      check.assertError(e)
      ol.find('div').html(e.message)
      setTimeout(() => {
        ol.fadeOut('fast', () => ol.remove())
        this.base.find('input,button').prop('disabled', false)
      }, 3000)
    }
  }

  private collectValues(): QuizResult {
    const ffs = $('.quiz__question-sendform input')
    const contestant: Record<string, string> = {}

    ffs.each((_, e) => {
      const el = e as HTMLInputElement
      contestant[el.name] = el.value
    })

    const qas: string[] = []

    this.pages?.each((_, e) => {
      if (e.classList.contains('quiz__question-sendform')) {
        return
      }

      const answer = $(e).find('input[type=radio]:checked').val()

      if (answer && typeof answer === 'string') {
        qas.push(answer)
      }
    })

    if (qas.length + 1 !== this.pages?.length) {
      throw new Error('Not all questions answered')
    }

    return {
      contestant,
      answers: qas,
    }
  }

  private getEndpoint(): string {
    const quizId = this.base.data('quiz-id')

    if (!quizId) {
      throw new Error('Bad Quiz. No Quiz ID found')
    }

    let ep = this.options.apiEndpoint ?? this.defaultEndpoint

    if (!ep.endsWith('/')) {
      ep += '/'
    }

    return `${ep}${quizId}`
  }
}

export function installQuizPlugin(): void {
  const NAME = 'quiz'
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const JqueryNoConflict = $.prototype[NAME]

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  $.prototype[NAME] = Quizzer._jqueryInterface
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  $.prototype[NAME].Constructor = Quizzer
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  $.prototype[NAME].noConflict = (): unknown => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    $.prototype[NAME].noConflict = JqueryNoConflict

    return Quizzer._jqueryInterface
  }
}
