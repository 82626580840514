import 'jquery'
import type { Maybe, PlainObject } from '@roxen/shared'
import { renderAds } from './ads/adnuntius'

export function initArticleTail(): void {
  const nws = window.NWS
  const feedName = 'bygg-no-forsiden'
  const tailconf = window.NWS.getConfig<PlainObject>('article tail') ?? {}
  const tailLength = (tailconf.partialMaxItems as Maybe<number>) ?? 5

  const cutTail = (): void => {
    const tail = $('.article-tail .front')
    const items = tail.find('.dre-group')

    if (items.length > tailLength) {
      let nitems = 0

      for (let i = 0; i < items.length; i++) {
        const item = items.get(i)

        if (!item || !$(item).find('.ad-wrapper').length) {
          nitems += 1
        }

        if (item && nitems >= tailLength) {
          item.parentNode?.removeChild(item)
        }
      }

      renderAds({ area: ['feed'], container: tail[0] })
    }
  }

  $('.article-tail').each((_idx, el) => {
    let endpoint = `/api/site/v1/dredition/${feedName}`
    const $el = $(el)

    if ($(el).data('endpoint')) {
      endpoint = $(el).data('endpoint')
    }

    void $.get(endpoint).then((resp: { ok: boolean; data: PlainObject }) => {
      if (resp.ok) {
        // Default Dr.E feed tail
        if (resp.data?.html) {
          nws.loadAsset(
            resp.data.cssPath,
            () => {
              el.innerHTML = resp.data.html
              cutTail()
              $el.show()
            },
            'css'
          )
        } else {
          if (resp.data && typeof resp.data === 'string') {
            $el.html(resp.data).show()
          }
        }
      }
    })
  })
}
