// Sync interface with nws-site
export interface EventListResponse {
  ok: boolean
  data?: string
}

export async function initEventList(): Promise<void> {
  if (window.NWS.isKalenderArticle()) {
    const res = (await $.ajax(`/api/site/v1/event-list`)) as EventListResponse

    if (res.ok && res.data) {
      $('#kalender-tail').append(res.data)
    }
  }
}
