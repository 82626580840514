import type { AdConfig } from './ads/inline-ads-layout'
import { layoutAdsFromConfig } from './ads/inline-ads-layout'

function noAds(): boolean {
  const m = window.NWS.misc
  return !!(
    m.isKalender ||
    m.isStilling ||
    m.isByggeprosjekt ||
    m.isNativeAd ||
    m.noAds
  )
}

export function initArticleInlinAds(): void {
  const nws = window.NWS

  if (nws.isArticle && !noAds()) {
    const t = $<HTMLDivElement>('#article-body-text')

    if (t.length) {
      inlineArticleAds(t)
    }
  }
}

const adsConfig: AdConfig[] = [
  {
    id: '0000000000051e33',
    desktop: true,
    mobile: true,
    width: 468,
    height: 80,
  },
]

function layoutAds(nodes: NodeListOf<Element>): void {
  if (nodes.length <= 4) {
    const html = layoutAdsFromConfig(0, adsConfig)
    const last = nodes.item(nodes.length - 1)

    if (html && last) {
      $(last).after(html)
      console.timeLog('ad:load', `Inline Article Ads Added (short article)`)
    }
  } else {
    let pos = 3
    let i = 0

    for (const el of Array.from(nodes)) {
      if (i > 3) {
        break
      } else if (i > 1 && el.classList.contains('dp-float-right')) {
        pos = i - 1
        break
      }

      i += 1
    }

    if (pos <= nodes.length) {
      const item = nodes.item(pos)
      const html = layoutAdsFromConfig(0, adsConfig)

      if (item && html) {
        $(item).after(html)
        console.timeLog('ad:load', `Inline Article Ads Added (long article)`)
      }
    }
  }
}

function inlineArticleAds(target: JQuery<HTMLDivElement>): void {
  const childTags = target[0].childNodes
  layoutAds(childTags as NodeListOf<Element>)
}
