import type { PlainObject } from '@roxen/shared'

const lipsum = (
  'Lorem ipsum dolor sit amet consectetur adipiscing elit ' +
  'Nulla ligula lacus pellentesque et arcu ac aliquam iaculis nunc ' +
  'Praesent a leo turpis Praesent a dignissim eros non venenatis erat ' +
  'Morbi mollis sapien id tincidunt maximus. Proin non sollicitudin est ' +
  'Duis sem erat efficitur in ullamcorper vitae auctor tincidunt orci'
).split(' ')

const liplen = lipsum.length

function genrnd(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function randString(minLen: number, maxLen: number): string {
  const rndlen = genrnd(minLen, maxLen)
  let out = ''

  while (out.length < rndlen) {
    out += lipsum[Math.floor(Math.random() * liplen)] + ' '
  }

  return out.trim()
}

function genShadowWidget(): JQuery<HTMLElement> {
  const loopCount = genrnd(5, 10)
  const out = $('<div>')
    .addClass('widget__shadow widget component')
    .attr('aria-hidden', 'true')
    .append(
      $('<h2>')
        .addClass('widget__title')
        .append($('<span>').addClass('shadow-text').text(randString(7, 25)))
    )

  const body = $('<div>').addClass('widget__body')

  for (let i = 0; i < loopCount; i++) {
    body.append(
      $('<p>')
        .addClass('shadow-item')
        .append($('<span>').addClass('shadow-text').text(randString(25, 70)))
    )
  }

  return out.append(body)
}

export function initColumns(): void {
  $('.column-widget').each((_idx, el) => {
    ;[1, 1, 1, 1].forEach(() => $(el).append(genShadowWidget()))

    const location = $(el).data('location')
    const prefix = ''

    const vars: PlainObject = {}
    const misc = window.NWS.misc

    if (misc.isStilling) {
      vars.first = 'stillinger'
    }

    const qs = Object.keys(vars)
      .map((k) => {
        return `${k}=${encodeURIComponent(vars[k])}`
      })
      .join('&')

    void $.getJSON(
      `/api/site/v1/column/${prefix}${location}${qs ? `?${qs}` : ''}`
    ).then((resp: PlainObject) => {
      if (resp.ok) {
        window.NWS.eventEmitter.once('contentinserted', () => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if ((resp.data ?? '').includes('fb-page') && window.FB) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            window.FB.init({ version: 'v9.0', xfbml: true })
          }
        })

        el.innerHTML = resp.data
        window.NWS.contentInserted(el)
      }
    })
  })
}
