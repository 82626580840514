import $ from 'jquery'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface JQuery<TElement = HTMLElement> {
    storste100(): this
  }

  interface Window {
    loadGoogleChartsLoader(callback: () => void): void
  }
}

class Storste100 {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static _jqueryInterface(): unknown {
    return (this as unknown as JQuery).each(function () {
      // eslint-disable-next-line @typescript-eslint/no-invalid-this
      new Storste100(this)
    })
  }

  private readonly base: JQuery<HTMLElement>

  public constructor(base: HTMLElement) {
    this.base = $(base)
    this.init()
  }

  private drawTable(): void {
    const year = 2023
    const query = 'SELECT A LIMIT 10'
    const googleQuery = new google.visualization.Query(
      `https://docs.google.com/a/aptoma.com/spreadsheet/ccc?headers=1&sheet=${year}&key=0AvmIOF7AjNlRdGgtYkN1TFpwdms4Y0VlRmtXY3BRQ3c`
    )
    googleQuery.setQuery(query)
    googleQuery.send(this.handleQueryResponse.bind(this))
  }

  private init(): void {
    window.loadGoogleChartsLoader(() => {
      window.google.load('visualization', '1', {
        packages: ['corechart', 'table'],
      })
      window.google.setOnLoadCallback(this.drawTable.bind(this))
    })
  }

  private handleQueryResponse(
    response: google.visualization.QueryResponse
  ): void {
    if (response.isError()) {
      this.base.closest('.widget.component').hide()
      return
    }

    const data = response.getDataTable()
    const baseItem = this.base.get(0)
    if (baseItem) {
      const table = new window.google.visualization.Table(baseItem)
      table.draw(data, { showRowNumber: false })
    }
  }
}

export function install100StorstePlugin(): void {
  const NAME = 'storste100'
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const JqueryNoConflict = $.prototype[NAME]

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  $.prototype[NAME] = Storste100._jqueryInterface
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  $.prototype[NAME].Constructor = Storste100
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  $.prototype[NAME].noConflict = (): unknown => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    $.prototype[NAME].noConflict = JqueryNoConflict
    return Storste100._jqueryInterface
  }
}
