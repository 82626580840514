// Sync interface with nws-site
export interface NativeadArticlesResponse {
  ok: boolean
  data?: {
    links: [
      {
        id: string
        title: string
        href: string
      }
    ]
  }
}

// The code below assumes the following html structure:
//
// <div class="text-component-layout-right">
//   ...
//   <div class="widget component readalso">
//     <div class="widget__title">Artikler fra Firesafe</div>
//     <div class="widget__body" data-company-name="Firesafe"></div>
//   </div>
//   ...
// </div>

export async function initNativeads(): Promise<void> {
  if (window.NWS.misc.isNativeAd) {
    const widget = $('.text-component-layout-right .widget.component.readalso')
    const widgetBody = widget.find('*[data-company-name]')
    const companyName = widgetBody.attr('data-company-name')

    if (companyName && companyName.length > 0) {
      const res = (await $.ajax(
        `/api/site/v1/nativeadarticles/${encodeURIComponent(companyName)}`
      )) as NativeadArticlesResponse

      const lis: string[] = []

      if (res.ok && res.data && res.data.links.length) {
        for (const link of res.data.links) {
          // Filter out the current article.
          if (link.id !== window.NWS.articleId) {
            lis.push(
              `<li class="list__item list__item--lg"><a class="list__link list__link--lg" href="${link.href}">${link.title}</a></li>`
            )
          }
        }
      }

      if (lis.length) {
        widgetBody.append(`<ul class="list">${lis.join('')}</ul>`)
        widget.show()
      }
    }
  }
}
