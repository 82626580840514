import $ from 'jquery'

export const initMenu = (): void => {
  function getScrollbarWidth(): number {
    const outer = document.createElement('div')
    outer.style.visibility = 'hidden'
    outer.style.width = '100px'
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // Vendor prefixes are unknown
    outer.style.msOverflowStyle = 'scrollbar'

    document.body.appendChild(outer)

    const widthNoScroll = outer.offsetWidth
    // force scrollbars
    outer.style.overflow = 'scroll'

    // add innerdiv
    const inner = document.createElement('div')
    inner.style.width = '100%'
    outer.appendChild(inner)

    const widthWithScroll = inner.offsetWidth

    // remove divs
    document.body.removeChild(outer)

    return widthNoScroll - widthWithScroll
  }

  const scrollWidth = getScrollbarWidth()

  // Handle scroll disabling and scroll bar
  $('#collapseMenu').on('show.bs.collapse', () => {
    $('html').addClass('menu-open')
    $('#main-header').addClass('main-header--menu-open')
    $('#overlay').removeClass('fade')
    $('#main-header').css({ paddingRight: scrollWidth })
    $('body').css({ paddingRight: scrollWidth })
    window.location.hash = '#menuopen'
  })
  $('#collapseMenu').on('hide.bs.collapse', () => {
    $('#overlay').addClass('fade')
    window.location.hash = ''
  })
  $('#collapseMenu').on('hidden.bs.collapse', () => {
    $('html').removeClass('menu-open')
    $('#main-header').removeClass('main-header--menu-open')
    $('body').css({ paddingRight: 0 })
    $('#main-header').css({ paddingRight: 0 })
  })

  $('#overlay').on('click', () => {
    $('#collapseMenu').collapse('hide')
  })

  $('#topMenu .subitems > a').on('click', (e) => {
    e.preventDefault()
    $(e.target).closest('li').toggleClass('open')

    return false
  })

  if (window.location.hash === '#menuopen') {
    $('#collapseMenu').collapse('show')
  }

  window.addEventListener('popstate', (_evt) => {
    if (window.location.hash === '#menuopen') {
      $('#collapseMenu').collapse('show')
    } else {
      $('#collapseMenu').collapse('hide')
    }
  })
}
