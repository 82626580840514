import $ from 'jquery'

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface JQuery<TElement = HTMLElement> {
    figureImg(options?: FigureImgConfig): this
  }
}

export interface FigureImgConfig {
  selector?: string
  ignoreParent?: string
}

class FigureImg {
  public static jQueryInterface<T0 extends JQuery>(
    this: T0,
    config: FigureImgConfig
  ): JQuery {
    return this.each(function <T1 extends HTMLElement>(this: T1) {
      new FigureImg(this, config)
    })
  }

  public elem: HTMLElement
  public readonly config: FigureImgConfig

  constructor(elem: HTMLElement, config?: FigureImgConfig) {
    this.elem = elem
    this.config = { selector: 'img', ...config }

    if (
      this.config.ignoreParent &&
      $(elem).closest(this.config.ignoreParent).length
    ) {
      return
    }

    this.adjust()
  }

  private adjust(): void {
    const img = $(this.elem).find(this.config.selector ?? 'img')

    // We're simply assuming length is one here. If it's greater something is
    // effed anyway
    if (img.length) {
      this.lowResolve(img as JQuery<HTMLImageElement>)
    }
  }

  private lowResolve(img: JQuery<HTMLImageElement>): void {
    const img0 = img.get(0)

    if (!img0?.complete || img0.naturalHeight === 0) {
      img.on('load', () => this.lowResolve(img))
    } else {
      const w = img.width()
      const h = img.height()

      if (w && h) {
        const ratio = (h / w) * 100
        $(this.elem).css({ paddingBottom: `${ratio}%` })
      }
    }
  }
}

export const installFigureImgPlugin = (): void => {
  const NAME = 'figureImg'
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const JqueryNoConflict = $.prototype[NAME]

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  $.prototype[NAME] = FigureImg.jQueryInterface
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  $.prototype[NAME].Constructor = FigureImg
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  $.prototype[NAME].noConflict = (): unknown => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    $.prototype[NAME].noConflict = JqueryNoConflict
    return FigureImg.jQueryInterface
  }
}
