/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import $ from 'jquery'

export interface User {
  state: number
  hasAccess: boolean
  enabled?: boolean
  name?: string
  email?: string
  isIpBasedAccess?: boolean
}

export function isUser(o: unknown): o is User {
  return typeof o === 'object' && o !== null && 'state' in o
}

function caps(t: string): string {
  if (t.includes('@')) {
    return t
  }

  try {
    return t
      ?.split('-')
      .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
      .join('-')
  } catch {
    return t
  }
}

const replaceUser = (u: unknown): void => {
  if (typeof u === 'undefined') {
    return
  }

  const paywallConfig = window.NWS.getConfig('paywall')
  const btn = $('#userstate')

  if (localStorage.user && (!u || typeof u === 'number')) {
    // Replace to logg in
    btn
      .text('Logg inn')
      .attr('href', paywallConfig.loginEndpoint)
      .removeClass('icon-user-ok')

    localStorage.removeItem('user')
  }
  // Real user object
  else if (isUser(u)) {
    localStorage.setItem('user', JSON.stringify(u))
    btn.attr(
      'href',
      u.hasAccess && !u.isIpBasedAccess
        ? paywallConfig.mypagesUrl
        : paywallConfig.logoutEndpoint
    )

    const device = window.NWS.device === 'mobile' ? 'mobile' : 'desktop'
    const usermenu = $(`#menu-user-${device}`)
    usermenu.removeClass('d-none')

    $(`#menu-user-${device}-logout`).attr('href', paywallConfig.logoutEndpoint)

    const mypageItem = $(`#menu-user-${device}-mypage`)
    const purchaseItem = $(`#menu-user-${device}-purchase`)
    const userlinks = $(`#menu-user-${device}-links`)

    if (u.hasAccess) {
      mypageItem
        .find('a')
        .attr(
          'href',
          u.isIpBasedAccess
            ? paywallConfig.logoutEndpoint
            : paywallConfig.mypagesUrl
        )
      purchaseItem.hide()

      if (u.isIpBasedAccess) {
        userlinks.remove()
        $(`#menu-user-${device}-ipinfo`).removeClass('d-none')
      }
    } else {
      mypageItem.hide()
      purchaseItem.find('a').attr('href', paywallConfig.purchaseEndpoint)
    }

    if (u.name) {
      $(`#menu-user-${device}-name`).text(u.name)
      btn.text(caps(u.name.split(' ')[0]))
    } else if (u.email) {
      $(`#menu-user-${device}-name`).text(u.email)
      btn.text(u.email.split('@')[0])
    } else if (u.isIpBasedAccess) {
      btn.text('Logg ut')
    } else {
      btn.text('Min side')
    }
  }
}

export const userState = (): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  window.NWS.eventEmitter.on('userLoaded', (u: any) => {
    replaceUser(u)
  })
}
