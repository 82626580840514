import $ from 'jquery'
import { initNavbar } from '@roxen/nws-public'
import { initMenu } from './menu'
import { initGDPR } from './gdpr'
import { initPrintIcons } from './print'
import { initGalleria } from './misc/galleria'
import { initArticleTail } from './article-tail'
import { initColumns } from './column'
import { initDynamicPush } from './dynamic-push'
import { initCalendarWidget } from './init-calendar-widget'
import { initSendForm } from './init-sendform'
import { initQuiz } from './init-quiz'
import { initSectionPage } from './sectionpage'
import { initAds } from './ads/adnuntius'
import { init100Storste } from './init-100-storste'
import { initNativeads } from './nativeads'
import { initEventList } from './event-list'
import { initArticleInlinAds } from './article-inline-ads-layout'
import { initFigureImg } from './init-figure-img'
import { userState } from './user-state'
import { initPaywall } from './paywall'
import { initModalHack } from './init-modal-hack'
import { initDisableOnSubmit } from './disable-on-submit'
import { initKilkaya } from './kilkaya'
import { initTrackPurchase } from './track-purchase'

export function onLoad(): void {
  console.timeLog('ad:load', 'main script onLoad() fired')
  initArticleInlinAds() // NOTE: This needs to be before "initAds()"
  initAds()
  userState()
  initKilkaya()
  initPaywall()
  initDynamicPush()
  initFigureImg()
  initArticleTail()
  initNavbar()
  initMenu()
  initGDPR()
  initPrintIcons()
  initGalleria()
  initQuiz()
  initCalendarWidget()
  initSendForm()
  // initFaq()
  initSectionPage()
  init100Storste()
  initColumns()
  void initNativeads()
  void initEventList()
  initModalHack()
  initDisableOnSubmit()
  initTrackPurchase()

  // Open links in new window and from public site when in preview.
  if (document.body.classList.contains('preview-page')) {
    $('.article-text-center a[href]').each((_i, el) => {
      const jQel = $(el)
      jQel.attr('target', '_blank')
      const href = jQel.attr('href') ?? ''
      if (href.startsWith('/') && !href.startsWith('//')) {
        jQel.attr('href', `${window.NWS.staticConfig['site url']}${href}`)
      }
    })
  }

  if (!window.NWS.gaInstance) {
    window.NWS.removeTrackingVariables()
  }
}
