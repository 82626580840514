import $ from 'jquery'

function onClick(evt: JQuery.ClickEvent): void {
  evt.preventDefault()
  window.print()
}

export function initPrintIcons(): void {
  $('.article-share .icon-print').each((_i: number, el: HTMLElement) => {
    $(el).on('click', onClick)
  })
}
