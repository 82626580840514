import 'bootstrap'
import { installDynamicPushPlugin } from '@roxen/nws-public'
import {
  installQuizPlugin,
  installCalendarPlugin,
  installSendFormPlugin,
  install100StorstePlugin,
} from './jq-plugins'
import { initNWS } from './bootstrap'
import { installFigureImgPlugin } from './jq-plugins/figure-img'

installQuizPlugin()
installDynamicPushPlugin()
installSendFormPlugin()
installCalendarPlugin()
install100StorstePlugin()
installFigureImgPlugin()
initNWS()
