export function initDisableOnSubmit(): void {
  const forms = $('[data-disable-on-submit]')

  if (forms.length) {
    forms.each((_, frm) => {
      $(frm).on('submit', () => {
        let d = frm.getAttribute('data-disable-on-submit')

        if (!d) {
          d = 'input,textarea,select,submit,reset'
        }

        $(frm).find(d).attr('disabled', 'disabled')
      })
    })
  }
}
