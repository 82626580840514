export function initQuiz(): void {
  window.NWS.onContentInserted((container?: HTMLElement) => {
    $(container ?? document.body)
      .find('.quiz')
      .quiz({
        texts: {
          next: 'Neste',
          previous: 'Forrige',
          submit: 'Sende',
          sending: 'Sender',
        },
      })
  }, true)
}
