import { NWS } from '@roxen/nws-public'

type ContentInsertedCallback = (el?: HTMLElement) => void

export class CustomerNws extends NWS {
  constructor(public readonly isDebug = false) {
    super(isDebug)

    // Customer specific code here

    this.loadAsset(
      'https://connect.facebook.net/nb_NO/sdk.js#xfbml=1&version=v9.0',
      'js'
    )
  }

  public contentInserted(container: HTMLElement): void {
    this.eventEmitter.emit('contentinserted', container)
  }

  public onContentInserted(
    callback: ContentInsertedCallback,
    immediate?: boolean
  ): void {
    this.eventEmitter.on('contentinserted', callback)
    immediate && callback()
  }

  public isKalenderArticle(): boolean {
    return this.misc.isKalender
  }

  public adDevice(): 'mobile' | 'desktop' {
    if (this.device === 'mobile') {
      return this.device
    } else {
      return 'desktop'
    }
  }
}
