import type { PlainObject } from '@roxen/shared'
import { getOriginCookie, unsetOriginCookie } from './track-purchase'
import { isUser } from './user-state'

interface K5aMeta extends PlainObject {
  login?: 1
  subscriber?: 1
  conversion?: 1
}

let k5aMeta: K5aMeta = {}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
window.k5aMeta = k5aMeta

export function initKilkaya(): void {
  window.NWS.eventEmitter.on('userLoaded', (user: unknown) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (window.paywallPurchaseDone /* Set in route-finalize.ts */) {
      const origin = getOriginCookie()
      k5aMeta.conversion = 1

      if (origin) {
        const source = origin.source
        delete origin.source

        if (source) {
          k5aMeta.tag = [`purchase-source:${source}`]
        }

        k5aMeta = { ...k5aMeta, ...origin }

        if (origin.author) {
          k5aMeta.type = 'article'
        }

        unsetOriginCookie()
      }
    }

    if (isUser(user)) {
      k5aMeta.login = 1

      if (user.enabled) {
        k5aMeta.subscriber = 1
      }
    }

    // Log whatever is set in <meta> tags + whatever is in k5aMeta by
    // loading the script.
    void $.getScript({
      url: '//cl.k5a.io/60ae49163984713a036f5c02.js',
      cache: true,
    })
  })
}
