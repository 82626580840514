import $ from 'jquery'

export const initGDPR = (): void => {
  const cookieName = 'gdprok'

  const setCookie = (): void => {
    $('.gdpr-info')
      .attr('aria-hidden', 'true')
      .fadeOut('normal', () => {
        $('.gdpr-info').addClass('d-none')
      })
    const date = new Date()
    // Expire in about 5 years. Update time for every page view.
    date.setTime(date.getTime() + 5 * 365 * 24 * 60 * 60 * 1000)
    const expires = '; expires=' + date.toUTCString()
    document.cookie = `${encodeURIComponent(cookieName)}=1${expires}; path=/`
  }

  const getCookies = (): Record<string, string> => {
    const cookies: Record<string, string> = {}
    document.cookie.split(';').map((s) => {
      const cParts = s.trim().split('=')
      if (cParts.length === 2) {
        cookies[cParts[0]] = decodeURIComponent(cParts[1])
      }
    })
    return cookies
  }

  const cookies = getCookies()

  if (cookies[cookieName]) {
    setCookie()
  } else {
    $('.gdpr-info').hide().removeClass('d-none').fadeIn()
    $('.gdpr-info button.gdpr-ok').on('click', () => {
      setCookie()
    })
  }
}
